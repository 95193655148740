import { getTicketRecords, getTicketRecordsByCode, getTicketRecordsByPlate } from '@/api/tickets'
import { createCashPaymentInfo, createAbnormalityInfo } from '@/api/payment'
import { createMachine } from 'xstate'

const posMachine = createMachine({
  id: 'pos',
  initial: 'loading',
  context: {
    ticket_record: [],
    page: 1,
    token: null,
    searchPlate: null,
    searchCode: null,
    selected: [],
    dialog: false,
    dialogMessage: '',
    checkSnackbar: false,
    alertMessage: ''
  },
  states: {
    loading: {

      invoke: {
        src: (context, event) => getTicketRecords(context.page, context.token),
        onDone: {
          target: 'idle',
          actions: (context, event) => {
            context.ticket_record = event.data
            context.selected = []
          }
        },
        onError: 'error'
      }
    },
    idle: {
      on: {
        CLICK_SEARCH_BY_PLATE: [
          {
            cond: 'checksearchPlate',
            target: 'check_alert'
          },
          {
            target: 'serach_plate'
          }
        ],
        CLICK_SEARCH_BY_CODE: [
          {
            cond: 'checksearchCode',
            target: 'check_alert'
          },
          {
            target: 'serach_code'
          }
        ],
        CLICK_PAY_BY_CASH: [
          {
            cond: 'checkSelectedEmpty',
            target: 'check_alert'
          },
          {
            target: 'cash_dialog'
          }
        ],
        CLICK_FREE: [
          {
            cond: 'checkSelectedEmpty',
            target: 'check_alert'
          },
          {
            target: 'free_dialog'
          }
        ],
      }
    },
    check_alert: {
      entry: (context, event) => {
        context.checkSnackbar = true
      },
      exit: (context, event) => {
        context.checkSnackbar = false
      },
      after: {
        1500: {
          target: 'idle'
        }
      }
    },
    feedback_alert: {
      entry: (context, event) => {
        context.checkSnackbar = true
        context.alertMessage = '操作成功'
      },
      exit: (context, event) => {
        context.checkSnackbar = false
        context.alertMessage = ''
      },
      after: {
        1500: {
          target: 'loading'
        }
      }
    },
    serach_plate: {
      invoke: {
        src: (context, event) => getTicketRecordsByPlate(context.searchPlate, context.token),
        onDone: {
          target: 'idle',
          actions: (context, event) => {
            context.ticket_record = event.data
            context.selected = []
            console.log(context.selected)
          }
        },
        onError: 'error'
      },
    },
    serach_code: {
      invoke: {
        src: (context, event) => getTicketRecordsByCode(context.searchCode, context.token),
        onDone: {
          target: 'idle',
          actions: (context, event) => {
            context.ticket_record = event.data
            context.selected = []
          }
        },
        onError: 'error'
      },
    },
    cash_dialog: {
      entry: (context, event) => {
        context.dialog = true
        context.dialogMessage = '確認變更單據狀態為現金繳費？'
      },
      exit: (context, event) => {
        context.dialog = false
        context.dialogMessage = ''
      },
      on: {
        SUBMIT: 'update_cash',
        CANCEL: 'idle'
      }
    },
    update_cash: {
      invoke: {
        src: (context, event) => createCashPaymentInfo(context.selected, context.token),
        // onDone: 'loading',
        onDone: 'feedback_alert',
        onError: 'idle'
      }
    },
    free_dialog: {
      entry: (context, event) => {
        context.dialog = true
        context.dialogMessage = '確認變更單據狀態為誤單免繳費？'
      },
      exit: (context, event) => {
        context.dialog = false
        context.dialogMessage = ''
      },
      on: {
        SUBMIT: 'update_free',
        CANCEL: 'idle'
      }
    },
    update_free: {
      invoke: {
        src: (context, event) => createAbnormalityInfo(context.selected, context.token),
        // onDone: 'loading',
        onDone: 'feedback_alert',
        onError: 'idle'
      }
    },
    error: {
      on: {
        RETRY: 'loading'
      }
    }
  }
}, {
  guards: {
    checkRecordEmpty: (context, event, { cond }) => {
      if (event.data.length === 0) {
        return true
      }
      return false
    },
    checkSelectedEmpty: (context, event, { cond }) => {
      if (context.selected.length === 0) {
        context.alertMessage = '請選擇單據'
        return true
      }
      return false
    },
    checksearchPlate: (context, event, { cond }) => {
      if (!context.searchPlate) {
        context.alertMessage = '請輸入車牌'
        return true
      }
      return false
    },
    checksearchCode: (context, event, { cond }) => {
      if (!context.searchCode) {
        context.alertMessage = '請輸入單號'
        return true
      }
      return false
    }
  }
})

export default posMachine
