<template>
  <v-row class="app-invoice-preview">
    <v-col
      cols="12"
      md="8"
    >
      <v-data-iterator
        :items="ticket_record"
        hide-default-footer
      >
        <!-- header -->
        <template v-slot:header>
          <v-toolbar
            class="mb-1"
            flat
          >
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="searchPlate"
                  label="請輸入車牌查詢"
                  single-line
                  hide-details
                  dense
                  outlined
                  clearable
                />

              </v-col>
              <v-col
                cols="12"
                sm="1"
              >
                <v-btn
                  fab
                  small
                  color="success"
                  @click="send('CLICK_SEARCH_BY_PLATE')"
                >
                  <v-icon>
                    {{ icons.mdiMagnify }}
                  </v-icon>
                </v-btn>
                <!-- <v-checkbox
                  v-model="statu0"
                  class="d-flex align-self-center"
                  label="只顯示未繳費單據"
                /> -->
                <!-- <v-select
                  v-model="statusFilter"
                  :items="statusOptions"
                  item-text="title"
                  item-value="value"
                  outlined
                  dense
                  hide-details
                ></v-select> -->
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="searchCode"
                  label="請輸入單號查詢"
                  single-line
                  hide-details
                  dense
                  outlined
                  clearable
                />

              </v-col>
              <v-col
                cols="12"
                sm="1"
              >
                <v-btn
                  small
                  fab
                  color="success"
                  @click="send('CLICK_SEARCH_BY_CODE')"
                >
                  <v-icon>
                    {{ icons.mdiMagnify }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template #default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.name"
              class="pb-0"
              cols="12"
            >
              <v-card>
                <v-card-title class="flex-start align-start justify-space-between py-0 px-5">
                  <v-checkbox
                    v-model="selected"
                    :value="item"
                    :disabled="item.status !== 0"
                    @change="countAmount"
                  >
                    <template #label>
                      單號：<span class="font-weight-bold secondary--text">{{ item.code }} </span>
                      <span>&nbsp;&nbsp;</span>
                      車號：<span class="font-weight-bold secondary--text">{{ item.plate }} </span>
                    </template>
                  </v-checkbox>

                  <div class="user-pricing align-self-center">
                    <span class="text-3xl font-weight-semibold primary--text">{{ item.amount }}</span>
                    <sub class="text-base font-weight-light">/ 元</sub>
                  </div>
                </v-card-title>
                <v-list
                  dense
                  class="py-0"
                >
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      時間: {{ convert_date(item.issued_at) }}
                    </v-list-item-content>
                    <template>
                      <v-chip
                        :color="statusColor[status[item.status]]"
                        :class="`${statusColor[status[item.status]]}--text`"
                        class="v-chip-light-bg font-weight-bold"
                      >
                        {{ status[item.status] }}
                      </v-chip>
                    </template>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      路段:  {{ road[item.spot_id] }}
                    </v-list-item-content>
                    <template>
                      <v-btn icon :href="get_photo(item)" target="_blank">
                        <v-icon
                          color="primary"
                        >
                          {{ icons.mdiImage }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <!-- <v-spacer /> -->
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-col>

    <!-- Right Column: Action -->
    <v-col
      cols="12"
      md="4"
    >
      <v-card class="font-weight-bold">
        <v-card-title class="font-weight-bold pt-4 pb-2">
          繳費
        </v-card-title>
        <v-divider />
        <v-list
          dense
          class="px-15"
        >
          <v-list-item>
            <v-list-item-content>停車費:</v-list-item-content>
            <span>{{ totalAmount }}</span>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>折扣:</v-list-item-content>
            <span>0</span>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>小計:</v-list-item-content>
            <span class="text-2xl font-weight-semibold primary--text">{{ totalAmount }}</span>
          </v-list-item>
        </v-list>
        <v-card-text>
          <v-btn
            class="mb-3"
            color="success"
            block
            @click="send('CLICK_PAY_BY_CASH')"
          >
            <v-icon
              class="me-2"
              left
            >
              {{ icons.mdiCurrencyUsd }}
            </v-icon>
            <span class="font-weight-bold">現金繳費</span>
          </v-btn>
        </v-card-text>
      </v-card>
      <v-card class="mt-4">
        <v-card-title class="font-weight-bold pt-4 pb-2">
          免繳
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-btn
            class="mb-3 font-weight-bold"
            color="secondary"
            block
            outlined
            @click="send('CLICK_FREE')"
          >
            誤單
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          {{ dialogMessage }}
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            outlined
            @click="send('CANCEL')"
          >
            取消
          </v-btn>
          <v-btn
            color="success"
            @click="send('SUBMIT')"
          >
            確定
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- snackbar -->
    <v-snackbar
      v-model="checkSnackbar"
      top
    >
      {{ alertMessage }}
    </v-snackbar>
  </v-row>
</template>

<script>
import { mdiSendOutline, mdiCurrencyUsd, mdiMagnify, mdiImage } from '@mdi/js'
import { interpret } from 'xstate'
import posMachine from '@/machine/posMachine'
import { road } from '@/@core/utils/filterKM'

export default {
  components: {},
  data: () => ({
    toggleService: interpret(posMachine),
    current: posMachine.initialState,
    context: posMachine.context,
    page: 1,
    road: road(),
    icons: {
      mdiSendOutline,
      mdiCurrencyUsd,
      mdiMagnify,
      mdiImage,
    },
    statu0: true,
    dialog: false,
    dialogMessage: '',
    checkSnackbar: false,
    alertMessage: '',
    searchPlate: '',
    searchCode: '',
    selected: [],
    totalAmount: 0,
    ticket_record: [],
    status: {
      0: '未付費',
      1: '已付費',
      2: '催繳',
      3: '免付費',
      4: '非正常',
    },
    statusColor: {
      未付費: 'error',
      已付費: 'success',
      催繳: 'warning',
      免付費: 'info',
      非正常: 'primary',
    },
  }),
  created() {
    this.toggleService
      .onTransition(state => {
        this.current = state
        this.context = state.context

        console.log(this.current.value)
        console.log(this.selected)
        this.selected = this.context.selected
        this.ticket_record = this.context.ticket_record
        this.dialog = this.context.dialog
        this.dialogMessage = this.context.dialogMessage
        this.checkSnackbar = this.context.checkSnackbar
        this.alertMessage = this.context.alertMessage
      })
      .start()
  },
  mounted() {},
  methods: {
    send(event) {
      this.context.selected = this.selected
      this.context.page = this.page
      this.context.searchPlate = this.searchPlate
      this.context.searchCode = this.searchCode
      this.context.dialog = this.dialog
      this.context.dialogMessage = this.dialogMessage

      this.toggleService.send(event)
    },
    countAmount() {
      this.totalAmount = 0
      console.log(this.selected)
      for (let i = 0; i < this.selected.length; i += 1) {
        this.totalAmount += this.selected[i].amount
      }
    },
    closeDialog() {
      this.confirmDialog = false
      this.message = ''
    },
    update() {
      this.confirmDialog = false
      this.message = ''
    },
    convert_date(date) {
      return date.replace(/.000Z/, '').replace(/T/, ' ')
    },
    get_photo(date) {
      // console.log('!')
      // console.log(date.photos)
      if (date.photos.length === 0) {
        return ''
      }
      return date.photos[0].photo_url
    },
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
