import request from '@/utils/request'

export async function createCashPaymentInfo(selectTickets, token) {
  const tickets = []
  for (let i = 0; i < selectTickets.length; i++) {
    tickets.push(parseInt(selectTickets[i].id))
  }

  const order = await request({
    url: '/order/create/cash',
    method: 'post',
    data: {
      tickets
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return order
}

export async function createAbnormalityInfo(selectTickets, token) {
  const tickets = []
  for (let i = 0; i < selectTickets.length; i++) {
    tickets.push(parseInt(selectTickets[i].id))
  }

  const order = await request({
    url: '/ticket/abnormality',
    method: 'post',
    data: {
      tickets
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return order
}
